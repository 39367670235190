var userImageUploadInput = document.getElementById("user-image-upload");
var userImageUploadImgTag = document.getElementById("user-image-upload-img");

// Preview the image after selecting with the input type file
if (userImageUploadInput) {
  userImageUploadInput.onchange = function () {
    var reader = new FileReader();

    reader.onload = function (e) {
      userImageUploadImgTag.src = e.target.result;
    };

    reader.readAsDataURL(this.files[0]);
  };

  $('input[name="user_image[display_date]"]').datepicker({
    dateFormat: "dd/mm/yy",
  });
}
