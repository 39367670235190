// KEEP THIS FIRST
import "~/javascripts/polyfils";

// LIBRARIES
// keep these imports before other libraries
import "~/javascripts/jquery";
import "~/javascripts/rails";

// other libraries
import "foundation-sites";
import "jquery-ui/ui/widget";
import "jquery-ui/ui/widgets/datepicker";

// OUR FILES
import "~/javascripts/annual_survey";
import "~/javascripts/api_keys";
import "~/javascripts/area_chart";
import "~/javascripts/banner";
import "~/javascripts/cookies";
import "~/javascripts/dropdown_menu";
import "~/javascripts/explore";
import "~/javascripts/external_links";
import "~/javascripts/mobile-nav";
import "~/javascripts/new_user_record";
import "~/javascripts/recaptcha.js";
import "~/javascripts/user_images";
import "~/javascripts/user_records";

$(function () {
  $(document).foundation();
});
