import * as d3 from "d3";
import { sumBy } from "lodash";
import noUiSlider from "nouislider";

// Area Chart

const areaChartData = $("#area-chart").data("decades");

if (areaChartData) {
  const minYear = 1000;
  const currentYear = new Date().getFullYear();

  const margin = { top: 32, right: 15, bottom: 32, left: 15 };
  const width = 1100;

  const chartContainerWidth = document
    .getElementById("area-chart")
    .getBoundingClientRect().width;
  let height;

  if (chartContainerWidth < 768) {
    height = 600 - margin.top - margin.bottom;
  } else {
    height = 400 - margin.top - margin.bottom;
  }

  const x = d3.scaleLinear().range([0, width]);

  const y = d3.scaleLinear().range([height, 0]);

  const svg = d3
    .select("#area-chart")
    .append("svg")
    .attr(
      "viewBox",
      `0 0 ${width + margin.left} ${height + margin.top + margin.bottom}`
    )
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top})`);

  x.domain(d3.extent(areaChartData, (d) => d.decade));
  y.domain(d3.extent(areaChartData, (d) => d.log_count));

  // Add the X axis

  svg
    .append("g")
    .attr("transform", `translate(0,${height})`)
    .call(
      d3.axisBottom(x).tickFormat((d) => {
        // remove the default , that gets added to the x axis.
        return d;
      })
    );

  const area = d3
    .area()
    .x((d) => x(d.decade))
    .y0(height)
    .y1((d) => y(d.log_count));

  // Append the area path

  svg
    .append("path")
    .datum(areaChartData)
    .attr("class", "area")
    .attr("d", area)
    .style("fill", "#D9D9D9");

  // Append the selected area path

  const selectedArea = d3
    .area()
    .x((d) => x(d.decade))
    .y0(height)
    .y1((d) => y(d.log_count));

  svg
    .append("path")
    .datum(areaChartData)
    .attr("class", "selected-area")
    .attr("d", selectedArea)
    .style("fill", "#9A2B2B");

  // Slider

  const sliderRange = document.getElementById("slider-range");

  noUiSlider.create(sliderRange, {
    start: [minYear, currentYear],
    pips: {
      mode: "range",
      density: 10,
    },
    tooltips: true,
    connect: true,
    range: {
      min: [1000],
      max: [currentYear],
    },
    format: {
      to: function (value) {
        return Math.floor(value);
      },
      from: function (value) {
        return Math.floor(value);
      },
    },
  });

  sliderRange.noUiSlider.on("update", function (values) {
    const min = Math.floor(values[0]);
    const max = Math.floor(values[1]);

    const filteredData = areaChartData.filter(
      (d) => d.decade >= min && d.decade <= max
    );

    const totalRecords = sumBy(filteredData, (decade) => {
      return decade.count;
    });

    let roundedTotalRecords;

    const round = (totalRecords, multipleOf) => {
      return Math.round(totalRecords / multipleOf) * multipleOf;
    };

    if (totalRecords > 1000000) {
      roundedTotalRecords = round(totalRecords, 1000000);
    } else if (totalRecords > 100000 && totalRecords < 1000000) {
      roundedTotalRecords = round(totalRecords, 100000);
    } else if (totalRecords > 10000 && totalRecords < 100000) {
      roundedTotalRecords = round(totalRecords, 10000);
    } else if (totalRecords > 1000 && totalRecords < 10000) {
      roundedTotalRecords = round(totalRecords, 1000);
    } else if (totalRecords > 100 && totalRecords < 1000) {
      roundedTotalRecords = round(totalRecords, 100);
    } else {
      roundedTotalRecords = round(totalRecords, 10);
    }

    svg.select(".selected-area").attr("d", selectedArea(filteredData));

    $("#js-explore-decade-text").text(
      `Explore approximately ${roundedTotalRecords.toLocaleString()} records from ${min} - ${max}`
    );
    $("#js-explore-decade").attr(
      "href",
      `/records?text=&page=1&from_year=${min}&to_year=${max}`
    );
  });

  $("body").on("keydown", function (e) {
    if ($(".noUi-handle").is(":focus")) {
      if (e.key == "Enter") {
        window.location.assign($("#js-explore-decade").attr("href"));
      }
    }
  });
}
