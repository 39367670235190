import $ from "jquery";
import { setCookie } from "./cookies";

$(function () {
  if ($("#survey-popup").length > 0) {
    handleAnnualSurvey();
  }
});

function incrementNbPageVisited(expires_in) {
  var nbPageVisited =
    $('button[data-open="survey-popup"]').data("nb-page-visited") + 1;
  setCookie("nb-page-visited", nbPageVisited.toString(), expires_in);
  return nbPageVisited;
}

function handleAnnualSurvey() {
  var six_month = 60 * 60 * 24 * 30 * 6;
  $(".survey-no-thanks").click(function () {
    setCookie("survey-completed", "true", six_month);
  });

  if (!$('button[data-open="survey-popup"]').data("survey-completed")) {
    // it can happen to be more than 3 if the user closed the tab
    // with the popup opened
    if (incrementNbPageVisited(six_month) >= 3) {
      setTimeout(function () {
        $("#survey-popup").foundation("open");
      }, 1000);
    }
  }
}
