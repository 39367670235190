$(function () {
  function hideSecondaryFormats() {
    $(".js-explore-expand-secondary-formats").addClass("hide");
    $(".js-explore-secondary-format").removeClass("hide");
  }

  function showSecondaryFormats() {
    $(".js-explore-minimise-secondary-formats").addClass("hide");
    $(".js-explore-secondary-format").addClass("hide");
    $(".js-explore-expand-secondary-formats").removeClass("hide");
  }

  $(".js-explore-expand-secondary-formats").on("click", function () {
    hideSecondaryFormats();
  });

  $(".js-explore-expand-secondary-formats").on("keydown", function (e) {
    if (e.key == "Enter") {
      hideSecondaryFormats();
    }
  });

  $(".js-explore-minimise-secondary-formats").on("click", function () {
    showSecondaryFormats();
  });

  $(".js-explore-minimise-secondary-formats").on("keydown", function (e) {
    if (e.key == "Enter") {
      showSecondaryFormats();
    }
  });
});
