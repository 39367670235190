import $ from "jquery";

export function setCookie(key, value, expires_in_sec) {
  $.post("/cookie.json", {
    key: key,
    value: value,
    expires_in: expires_in_sec,
  }).fail(function (e) {
    console.error("Failed to set " + key + "=" + value + ".");
  });
}
