import { concat, includes, remove } from "lodash";

$(function () {
  $("#landing_url").on("change", function () {
    $("#fragment_landing_url").val($(this).val());
  });

  $("#primary-source-true").on("click", function (e) {
    $(this).removeClass("hollow");
    $("#primary-source-false").addClass("hollow");
    toggleTags(e, "add", "s2s_te_primary_source");
  });
  $("#primary-source-false").on("click", function (e) {
    $(this).removeClass("hollow");
    $("#primary-source-true").addClass("hollow");
    toggleTags(e, "remove", "s2s_te_primary_source");
  });
});

function toggleTags(event, action, tag) {
  event.preventDefault();

  var tagSelector = $("select#tag-selector");
  var tags = tagSelector.val();

  if (tags === null) {
    tags = [];
  }

  if (action === "add") {
    if (includes(tags, tag) || tag === "") return;
    tagSelector.val(concat(tags, tag));
  } else {
    remove(tags, function (t) {
      return t === tag;
    });

    tagSelector.val(tags);
  }
}
