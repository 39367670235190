// https://github.com/foundation/foundation-sites/issues/11453#issuecomment-512707960
// should be re-evaluated with foundation 7
$(function () {
  $("[data-dropdown-menu]").on("show.zf.dropdownMenu", function (e) {
    $(e.target)
      .find('li a[aria-expanded="false"][role="menuitem"]')
      .attr("aria-expanded", true);
  });

  $("[data-dropdown-menu]").on("hide.zf.dropdownMenu", function (e) {
    $(e.target)
      .find('li a[aria-expanded="true"][role="menuitem"]')
      .attr("aria-expanded", false);
  });
});
