$(function () {
  $(".mobile-nav__toggle").on("click", function (e) {
    // Enter key sends click event on a <button>{
    $("body").toggleClass("js-mobile-nav-is-open");
    $(this).toggleClass("mobile-nav__toggle--closed");
    $(this).attr(
      "aria-expanded",
      $(this).hasClass("mobile-nav__toggle--closed")
    );

    $(".mobile-nav__menu").slideToggle();
    $(".mobile-nav__logo").toggleClass("mobile-nav__logo--open");
  });

  $(".secondary-nav__title__toggle").on("click", function (e) {
    // Enter key sends click event on a <button>
    $(".secondary-nav__menu").slideToggle();

    var $icon = $(this).find("i");
    $icon.toggleClass("fa-chevron-down fa-chevron-up");
    $(this).attr("aria-expanded", $icon.hasClass("fa-chevron-up"));
  });
});
